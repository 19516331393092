<template>
    <Layout v-if="cards.length > 1">
        <div class="home-container1">
            <div class="home-container2">
                <label class="home-text">{{ $t_('ingressos_oficiais') }}</label>
                <h2 class="home-text1">
                <span class="home-text2">{{ $t_('title_ingressos_of' )}}</span>
                </h2>
            </div>

            <div class="home-container3">
                <v-slide-group class="pa-4" show-arrows>
                    <v-slide-item  v-for="(item, index) in cards" :key="index">
                        <v-card class="ma-4" color="transparent">
                            <card-evento
                                :titulo="item.titulo"
                                :descricao="item.dscevento"
                                :image_src="item.imagem_media"
                                :idapresentacao="item.idapresentacao"
                            ></card-evento>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
            </div>
        </div>
    </Layout>
     <div v-else-if="cards.length == 0" class="semEventos animate__animated animate__fadeIn animate__delay-2s">
        <div class="text-center white--text">
            <img alt="logo" :src="$config.dados.logowhite" height="150" class="mx-auto my-5" />
            <h1>{{ $t_('temporariamente_indisponivel') }}</h1>
            <!-- <p>Entre em contato com <a href="mailto:faleconosco@canionsverdes.com.br">faleconosco@canionsverdes.com.br</a> | <a href="mailto:faleconosco@canionsverdes.com.br">0800 321 0101</a></p> -->
        </div>
    </div>
</template>

<script>



export default {
    name: 'Home',
    components: {
        Layout: () => import('@/layout/Default.vue'),
        CardEvento: () => import('@/components/card-evento.vue'),
    },
   
    data(){
        return {
            cards: []
        }
    },
    methods: {
        removeSAC() {
            if(this.$config.dados.SAC.status) document.getElementById('sac-flow').style.display = 'none'
        },
        getEventos(){
            this.$superingresso.getEventos()
            .then(response => {
                if(response){

                    if(response.length > 1) {
                        this.cards = response
                        return
                    }

                    if(response.length == 1) {
                        this.cards = response
                        let link = this.$superingresso.tratarLink(response[0].titulo)
                        this.$router.push({ name: 'Produto', params: { id: link } })   
                        return                     
                    }

                    if(response.length == 0) {
                        this.$axios.modalBar('', "Não existe apresentações")   
                        this.removeSAC()                    
                    }

                }
            })
        },
    },
    created() {
        this.getEventos()
    },
    
}
</script>

<style scoped>
.semEventos {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/maraca_indis.png');
    background-position: center;
    background-size: cover;
}
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container1 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.home-text1 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-transform: uppercase;
}
.home-text2 {
  text-transform: uppercase;
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container4 {
  flex: 0 0 auto;
  width: 33.33%;
  height: 390px;
  display: flex;
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container5 {
  flex: 0 0 auto;
  width: 33.33%;
  height: 390px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  justify-content: center;
}
.home-container6 {
  flex: 0 0 auto;
  width: 33.33%;
  height: 390px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  justify-content: center;
}
@media(max-width: 991px) {
  .home-container1 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-container2 {
    width: 100%;
  }
  .home-container3 {
    width: 100%;
    flex-wrap: wrap;
  }
  .home-container4 {
    width: 50%;
    margin-bottom: 20px;
  }
  .home-container5 {
    width: 50%;
    margin-bottom: 20px;
  }
  .home-container6 {
    width: 50%;
    margin-bottom: 20px;
  }
}
@media(max-width: 479px) {
  .home-container2 {
    width: 100%;
  }
  .home-container3 {
    width: 100%;
    padding: 0px;
  }
  .home-container4 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-container5 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-container6 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
